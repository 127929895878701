import React from 'react';
// import { Link } from 'gatsby';
import Layout from './../components/layout';
// import { LinkArrow } from '../components/link-arrow';

const NotFoundPage = () => {

	return (
		<Layout>
			<div className='flex flex-col items-center justify-center w-full h-screen px-8 bg-black'>
				{/* <h1 className='mb-16 font-light text-center uppercase text-48 leading-100'>Page not found!</h1>
				<p className='mb-48 font-light text-center'>We are sorry, but this page still needs some more time in the oven.</p>
				<Link className='px-8' to='/'>Go to the front-page<LinkArrow /></Link> */}
			</div>
		</Layout>
	);
};

export default NotFoundPage;
